import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import React, { useState, useEffect } from "react";

function HetuParikshaPage({
  patientId,
  hetuParikshaOpen,
  dialogHeading,
  setHetuParikshaOpen,
  clearButton,
  hetuParikshaData,
  selectedValues,
  rogaParikshaRadioChange,
  patientHetuParikshaData,
  setPatientHetuParikshaData,
  selectExcercis,
  saveButton,
  cancelButton,
  setPatientData,
  setEditPatient
}) {
  const [formData, setFormData] = useState({});
  useEffect(() => {

    if (patientHetuParikshaData?.data?.formData) {
      // const hetuData = buildHetuData(patientHetuParikshaData?.data?.formData);
      setFormData(patientHetuParikshaData?.data?.formData);
      // console.log("Hetupariksh", patientHetuParikshaData?.data?.formData)
    }
  }, [patientHetuParikshaData]);


  const editHetuPariksha = () => {
    setEditPatient((prevData) => {
      const existingFormData = prevData.formData || [];

      const hetuParikshaIndex = existingFormData.findIndex(
        (ele) => ele.type === "HETU_PARIKSHA"
      );

      if (hetuParikshaIndex !== -1) {
        existingFormData[hetuParikshaIndex] = {
          type: "HETU_PARIKSHA",
          formData: formData,
        };
      } else {
        existingFormData.push({
          type: "HETU_PARIKSHA",
          formData: formData,
        });
      }

      return {
        ...prevData,
        formData: existingFormData,
      };
    });

    setHetuParikshaOpen(false);
  };

  const handleTimePickerChange = (id, selectedTime) => {
    setFormData((prevData) => ({
      ...prevData,
      [id]: { ...prevData[id], timepicker: selectedTime },
    }));
  };

  const editTimePickerChange = (id, selectedTime) => {
    if(patientId){
      setFormData((prevData) => ({
        ...prevData,
        [id]: { ...prevData[id], timepicker: selectedTime },
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [id]: { ...prevData[id], timepicker: selectedTime },
      }));
    }
  };

  const handleRadioChange = (id, selectedValue) => {
    setFormData((prevData) => ({
      ...prevData,
      [id]: { ...prevData[id], radio: selectedValue },
    }));
  };

  const editRadioChange = (id, selectedValue) => {
    console.log("Edit radio", selectedValue)
    setFormData((prevData) => ({
      ...prevData,
      [id]: { ...prevData[id], answer1: selectedValue },
    }));
  };

  const handleCheckboxChange = (questionId, selectedValues, checked) => {
    console.log("handle checkbox", selectedValues)
    setFormData((prevData) => ({
      ...prevData,
      [questionId]: { ...prevData[questionId], checkbox: selectedValues },
    }));
  };

  const editCheckboxChange = (questionId, selectedValues, checked) => {
      console.log("edit checkbox", selectedValues, checked)
      if(checked){
        console.log("Edit checkbox, formdata", formData);
        setFormData((prevData) => ({

          ...prevData,
          [questionId]: prevData[questionId]?.answer1 ? { ...prevData[questionId], answer1: [...prevData[questionId].answer1, ...selectedValues]}: { ...prevData[questionId], answer1: [...selectedValues]},
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,
          [questionId]: { ...prevData[questionId], answer1: prevData[questionId].answer1.filter(item => item != selectedValues[0])},
        }));
      }
      
  };

  const handleSelectChange = (id, selectedValues) => {
    setFormData((prevData) => ({
      ...prevData,
      [id]: { ...prevData[id], select: selectedValues },
    }));
  };

  const editSelectChange = (id, selectedValues) => {
    console.log("edit select",selectedValues);
    setFormData((prevData) => ({
      ...prevData,
      [id]: { ...prevData[id], answer2: selectedValues.map(item => item?.label ? item.label: item)},
    }));
  };

  const handleTextChange = (id, textValue) => {
    setFormData((prevData) => ({
      ...prevData,
      [id]: { ...prevData[id], text: textValue },
    }));
  };

  const editTextChange = (id, textValue) => {
    //check hetuparikshadata type array with text
    const checktext = hetuParikshaData[id].type.findIndex(item => item === "text") > 0
    setFormData((prevData) => ({
      ...prevData,
      [id]: { ...prevData[id], ...(checktext ? {answer2: textValue} : {answer1: textValue}) } ,
    }));
  };

  const buildHetuData = (localFormData) => {
    const structuredFormData = hetuParikshaData.map((ele) => {
      const questionId = ele.id;
      const answer1 = {
        type: ele.type[0],
        value: localFormData[ele.id]?.[ele.type[0]] || null,
      };
      const answer2 = ele.type[1]
        ? {
            type: ele.type[1],
            value: Array.isArray(localFormData[ele.id]?.[ele.type[1]])
              ? localFormData[ele.id]?.[ele.type[1]].map(
                  (option) => option.label || option
                )
              : localFormData[ele.id]?.[ele.type[1]],
          }
        : null;

      return {
        questionId,
        answer1,
        answer2,
      };
    });

    // console.log("structuredFormData" , structuredFormData);
    

    const hetuData = {
      type: "HETU_PARIKSHA",
      formData: structuredFormData.reduce((acc, curr) => {
        acc[curr.questionId] = {
          answer1: curr.answer1.value,
          answer2: curr.answer2 ? curr.answer2.value : null,
        };
        return acc;
      }, {}),
    };
    return hetuData;
  }
  const handleSaveButtonClick = () => {
    
    const hetuData = buildHetuData(formData);
    setPatientData((prevData) => ({
      ...prevData,
      formData: [...prevData.formData, hetuData],
    }));
    setHetuParikshaOpen(false);
  };

  return (
    <>
      <Dialog
        open={hetuParikshaOpen}
        maxWidth="md"
        fullWidth={true}
        scroll={"paper"}
      >
        <DialogTitle id="alert-dialog-title">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography sx={dialogHeading}>Hetu Pariksha</Typography>
            <IconButton
              onClick={() => {
                setHetuParikshaOpen(false);
              }}
            >
              <ClearIcon sx={clearButton} />
            </IconButton>
          </Box>
          <Divider
            sx={{ height: 1, backgroundColor: "Black", marginY: "10px" }}
          />
        </DialogTitle>
        <DialogContent>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontSize: "18px", fontWeight: "500" }}>
                    No.
                  </TableCell>
                  <TableCell
                    colSpan={5}
                    sx={{ fontSize: "18px", fontWeight: "700" }}
                  >
                    પ્રશ્ન/Questions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {hetuParikshaData.map((ele, idx) => (
                  <TableRow key={ele.id}>
                    <TableCell>{ele.id}</TableCell>
                    <TableCell>{ele.questions}</TableCell>
                    <TableCell>
                      {ele.type.map((type) => {
                        if (type === "timepicker") {
                          return (
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoItem>
                                <TimePicker
                                  sx={{ ml: 2, width: "50%" }}
                                  label="Select Time"
                                  // onChange={(selectedTime) =>
                                  //   handleTimePickerChange(ele.id, selectedTime)
                                  // }
                                  value = { 
                                    formData[idx]?.answer1 || null
                                  }
                                  onChange={(selectedTime) => {
                                    patientId ?
                                      editTimePickerChange(
                                        idx, selectedTime
                                      )
                                    :
                                      handleTimePickerChange(
                                        idx, selectedTime
                                      );
                                  }}
                                />
                              </DemoItem>
                            </LocalizationProvider>
                          );
                        } else if (type === "radio") {
                          return (
                            <FormControl key={`radio_${ele.id}`}>
                              <RadioGroup
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                                name={`radio_${ele.id}`}
                                value = { 
                                  patientId &&
                                  formData[idx]?.answer1
                                }
                                onChange={(event) =>
                                  patientId ?
                                  editRadioChange(ele.id, event.target.value):
                                  handleRadioChange(ele.id, event.target.value)
                                }
                              >
                                {ele.options1.map((check, index) => (
                                  <FormControlLabel
                                    key={index}
                                    sx={{ ml: 2 }}
                                    value={check}
                                    control={<Radio />}
                                    label={check}
                                    name={`radio_${ele.id}`}
                                  />
                                ))}
                              </RadioGroup>
                            </FormControl>
                          );
                        } else if (type === "select") {
                          return (
                            <Autocomplete
                              disablePortal
                              value = { 
                                patientId &&
                                formData[idx]?.answer2
                              }
                              key={`select_${ele.id}`}
                              // id="combo-box-demo"
                              id={`select_${ele.id}`}
                              options={selectExcercis}
                              sx={{ width: "100%" }}
                              isOptionEqualToValue={(option, value) => option.label === value} 
                              multiple
                              onChange={(_, selectedValues) =>
                                patientId ? 
                                editSelectChange(ele.id, selectedValues): 
                                handleSelectChange(ele.id, selectedValues)
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Select Excercis..."
                                />
                              )}
                            />
                          );
                        } else if (type === "checkbox") {
                          return (
                            <FormControl
                              sx={{
                                ml: 2,
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                              }}
                            >
                              {ele.options1 !== null &&
                              Array.isArray(ele.options1)
                                ? ele.options1.map((check, index) => (
                                    <FormControlLabel
                                      key={index}
                                      sx={{ ml: 2 }}
                                      control={
                                        <Checkbox
                                          checked={
                                            patientId ?
                                            
                                            formData[
                                              idx
                                            ]?.answer1 && formData[
                                                  idx
                                                ]?.answer1.includes(check)
          
                                            :
                                            formData[ele.id]?.checkbox
                                              ? formData[
                                                  ele.id
                                                ]?.checkbox.includes(check)
                                              : false
                                          }
                                          // value = { 
                                          //   patientId &&
                                          //   formData[ele.id]?.checkbox.filter(item => formData[idx].answer1.includes(item))
                                          // }
                                          onChange={(event) => patientId ?
                                            
                                            editCheckboxChange(
                                              ele.id,
                                              event.target.checked
                                                ? [
                                                    ...(formData[ele.id]
                                                      ?.checkbox || []),
                                                    check,
                                                  ]
                                                : formData[
                                                    ele.id
                                                  ]?.answer1.filter(
                                                    (value) => value !== check
                                                  ), event.target.checked
                                            ):
                                            handleCheckboxChange(
                                              ele.id,
                                              event.target.checked
                                                ? [
                                                    ...(formData[ele.id]
                                                      ?.checkbox || []),
                                                    check,
                                                  ]
                                                : formData[
                                                    ele.id
                                                  ]?.checkbox.filter(
                                                    (value) => value !== check
                                                  )
                                            )
                                          }
                                        />
                                      }
                                      label={check}
                                    />
                                  ))
                                : ele.options2 !== null &&
                                  Array.isArray(ele.options2)
                                ? ele.options2.map((check, index) => (
                                    <FormControlLabel
                                      key={index}
                                      sx={{ ml: 2 }}
                                      control={
                                        <Checkbox
                                          checked={
                                            formData[
                                              idx
                                            ]?.answer2 && formData[
                                                  idx
                                                ]?.answer2.includes(check)
                                          }
                                          onChange={(event) =>
                                            handleCheckboxChange(
                                              ele.id,
                                              event.target.checked
                                                ? [
                                                    ...(formData[ele.id]
                                                      ?.checkbox || []),
                                                    check,
                                                  ]
                                                : formData[
                                                    ele.id
                                                  ]?.checkbox.filter(
                                                    (value) => value !== check
                                                  )
                                            )
                                          }
                                        />
                                      }
                                      label={check}
                                    />
                                  ))
                                : null}
                            </FormControl>
                          );
                        } else if (type === "text") {
                          return (
                            <TextField
                              sx={{ ml: 2, width: "70%" }}
                              placeholder={ele.placeholder}
                              size="small"
                              value= { patientId ? (hetuParikshaData[ele.id].type.findIndex(item => item === "text") > 0 ? formData[idx]?.answer2 : formData[idx]?.answer1) : null}
                              onChange={(event) =>
                                patientId ?
                                editTextChange(ele.id, event.target.value):
                                handleTextChange(ele.id, event.target.value)
                              }
                            />
                          );
                        } else {
                          return null; // Render nothing for other types
                        }
                      })}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Box display="flex" margin="0px">
            <Button
              sx={saveButton}
              variant="contained"
              onClick={patientId ? editHetuPariksha : handleSaveButtonClick}
            >
              Save
            </Button>
            <Button
              onClick={() => setHetuParikshaOpen(false)}
              sx={cancelButton}
              variant="outlined"
            >
              Cancel
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default HetuParikshaPage;
